const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|e\/|.+?\?v=)?([a-zA-Z0-9_-]{11})(\S*)?/g

const spotifyRegexWithinPTags =
    /<p>(\s*)https:\/\/open\.spotify\.com\/episode\/([a-zA-Z0-9]+)(\s*)<\/p>/g

const twitterRegex =
    /(?:https?:\/\/)?(?:www\.)?(twitter\.com|x\.com)\/(\w+)\/status\/(\d+)/g

/**
 * Represents the JOTAINFO class.
 */
export class JotaInfoEmbeddings {
    private static _htmlContent: string

    /**
     * Initializes the HTML content.
     * @param {string} htmlContent The HTML content.
     * @return {JotaInfoEmbeddings}
     */
    public static init(htmlContent: string): typeof JotaInfoEmbeddings {
        this._htmlContent = htmlContent
        return this
    }

    /**
     * Spotify - Check and Embedding
     * @return {JotaInfoEmbeddings}
     */
    public static checkSpotify(): typeof JotaInfoEmbeddings {
        this._htmlContent = this._htmlContent.replace(
            spotifyRegexWithinPTags,
            (match, p1, p2, p3) => {
                const spotifyEmbedUrl = `https://open.spotify.com/embed/episode/${p2}?utm_source=generator`
                return `<p>${p1}<iframe src="${spotifyEmbedUrl}" width="100%" height="auto" frameborder="0" 
                allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>${p3}</p>`
            }
        )

        return this
    }

    /**
     * Youtube - Check and Embedding
     * @return {JotaInfoEmbeddings}
     */
    public static checkYouTube(): typeof JotaInfoEmbeddings {
        const youtubeEmbedInIframeRegex =
            /<iframe[^>]*src=["'][^"']*youtube\.com\/embed\/[^"']*["'][^>]*><\/iframe>/g

        const existingIframes: string[] = []
        this._htmlContent = this._htmlContent.replace(
            youtubeEmbedInIframeRegex,
            (match) => {
                existingIframes.push(match)
                return `[[IFRAME_PLACEHOLDER_${existingIframes.length - 1}]]`
            }
        )

        this._htmlContent = this._htmlContent.replace(
            youtubeRegex,
            '<iframe width="100%" height="315" src="https://www.youtube.com/embed/$3" frameborder="0" allowfullscreen></iframe>'
        )

        this._htmlContent = this._htmlContent.replace(
            /\[\[IFRAME_PLACEHOLDER_(\d+)\]\]/g,
            (match, index) => existingIframes[index]
        )

        return this
    }

    /**
     * Twitter - Check and Embedding
     * @return {JotaInfoEmbeddings}
     */
    public static checkTwitter(): typeof JotaInfoEmbeddings {
        this._htmlContent = this._htmlContent.replace(
            twitterRegex,
            (match, domain, username, tweetId) => {
                const tweetUrl = `https://${domain}/${username}/status/${tweetId}`
                const twitterEmbedHtml = `
                    <blockquote class='twitter-tweet'>
                        <a href='${tweetUrl}'></a>
                    </blockquote>
                `
                return twitterEmbedHtml
            }
        )

        return this
    }

    /**
     * Check all the social media embeddings.
     * @return {this}
     */
    public static check(): typeof JotaInfoEmbeddings {
        this.checkSpotify()
        this.checkYouTube()
        this.checkTwitter()

        return this
    }

    /**
     * Gets the HTML content.
     * @return {string} The HTML content.
     */
    public static getHtml(): string {
        return this._htmlContent
    }
}
